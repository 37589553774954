import styled from "@emotion/styled"

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
`

const SectionBorder = styled.div`
  background-color: #8E7357;
  height: 1px;
  margin-left: 2rem;
  margin-right: 2rem;
  flex: 1 1 0;
`

export { SectionHeader, SectionBorder }
