import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage as Img } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart, faBookOpen } from "@fortawesome/free-solid-svg-icons"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { Title } from "./title"
import Layout, { Wide } from "./layout"
import { SectionHeader, SectionBorder } from './index/section'
import { TwitterShareLarge, TwitterShareSmall, FacebookShareLarge, FacebookShareSmall } from './social'
import SEO from "./seo"
import url from "../utils/url"

export const query = graphql`query ($id: String, $magazine: String) {
  magazine: mdx(id: {eq: $id}) {
    body
    frontmatter {
      title
      slug
      finished
      coverImage {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      description
    }
  }
  stories: allMdx(
    sort: {fields: [frontmatter___order, fields___date], order: ASC}
    filter: {fields: {category: {eq: "stories"}}, frontmatter: {magazine: {eq: $magazine}}}
  ) {
    edges {
      node {
        id
        fields {
          slug
        }
        frontmatter {
          title
          author
          short
          summary
        }
      }
    }
  }
  site {
    siteMetadata {
      siteUrl
    }
  }
}
`

const Magazine = ({data, location}) => {
  const magazine = data.magazine.frontmatter;
  const pageUrl = url(location, data.site.siteMetadata.siteUrl);
  return (
    <Layout>
      <SEO 
        title={magazine.title}
        description={magazine.description}
        image={magazine.coverImage.childImageSharp.gatsbyImageData.images.fallback}
        path={location.pathname}
      />
      <Wide>
        <SectionHeader className="mx-4">
          <Title>{magazine.title}</Title>
          <SectionBorder />
        </SectionHeader>
        <p className="mx-4 pb-4 flex items-center">
          <TwitterShareSmall title={magazine.title} url={pageUrl} />
          <FacebookShareSmall url={pageUrl} />
        </p>
        <div className="grid grid-cols-2 grid-rows-1 gap-6 sm:gap-12 mx-4">
          <div className="col-span-2 lg:col-span-1">
            <Img image={magazine.coverImage.childImageSharp.gatsbyImageData} alt="Vonk Magazine" />
          </div>
          <div className="col-span-2 lg:col-span-1 flex flex-wrap lg:flex-col">
            {data.stories.edges.map(({ node }) => {
              return (
                <Link className="flex-0 md:w-1/2 lg:w-full transition-all duration-300 mb-6 md:mb-12 block md:pl-2 md:border-l-4 border-transparent hover:border-red-900" to={node.fields.slug}>
                  <h3 className="tracking-tight font-bold text-2xl text-red-900 font-sans mb-1">{node.frontmatter.title}</h3>
                  <p className="tracking-tight text-xl text-gray-700 font-semibold mb-2">{node.frontmatter.author}</p>
                  <p className="text-gray-900">{node.frontmatter.short || node.frontmatter.summary }</p>
                </Link>
              )
            })}
            {/* {!magazine.finished && <Link className="flex-0 md:w-1/2 lg:w-full  relative p-4 pb-16 mb-4 text-gray-50 rounded-xl bg-red-600 shadow-xl hover:bg-red-900" to="/plus" target="_blank" rel="noreferrer">
              <p className="font-sans text-xl font-bold mb-2">Benieuwd naar de andere verhalen?</p>
              <p>Door lid te worden van Vonk+ ontvang je alle verhalen van Vonk Magazine voordat ze online komen. Je steunt daarmee ook de schrijvers van de verhalen. Lees hier meer.</p>
              <span className="absolute bottom-0 right-0 mr-8 mb-4 text-red-300 opacity-50"><FontAwesomeIcon size="4x" icon={faHeart} /></span>
            </Link>} */}
          </div>
        </div>
        <p className="flex items-center my-8 mx-4">
          <span className="mr-4">Deel deze verhalen op: </span>
          <FacebookShareLarge url={pageUrl} />
          <TwitterShareLarge  title={magazine.title} url={pageUrl} />
        </p>
        <div className="md:flex flex-wrap mx-4 items-stretch md:space-x-6">
          <div className="flex-1">
            <MDXProvider>
              <div className="prose prose-lg">
                <MDXRenderer>{data.magazine.body}</MDXRenderer>
              </div>
            </MDXProvider>
          </div>
          {/* {magazine.finished && <div className="flex-1">
            <Link className="h-full block relative p-4 pb-16 mb-4 text-gray-50 rounded-xl bg-red-600 shadow-xl hover:bg-red-900" to="/plus" target="_blank" rel="noreferrer">
              <p className="font-sans text-xl font-bold mb-2">Help jij Vonk Magazine?</p>
              <p>Vonk Magazine wordt mede mogelijk gemaakt door lezers zoals jij. Zo kunnen wij de schrijvers betalen voor hun harde werk. Wil je Vonk Magazine ook steunen? Dat kan door lid te worden van Vonk+. Je ontvangt dan Vonk Magazine ook als ebook voor de verhalen op de site staan.</p>
              <span className="absolute bottom-0 right-0 mr-8 mb-4 text-red-300 opacity-50"><FontAwesomeIcon size="4x" icon={faHeart} /></span>
            </Link>
          </div>} */}
          <div className="flex-1">
            <Link className="h-full block relative p-4 pb-16 mb-4 text-gray-50 rounded-xl bg-yellow-800 shadow-xl hover:bg-yellow-900" to="/verhalen/">
              <p className="font-sans text-xl font-bold mb-2">Meer verhalen lezen?</p>
              <p>Meer spannende, ontroerende en mooie verhalen vind je in deze andere edities van Vonk Magazine.</p>
              <span className="absolute bottom-0 right-0 mr-8 mb-4 text-yellow-300 opacity-50"><FontAwesomeIcon size="4x" icon={faBookOpen} /></span>
            </Link>
          </div>
        </div>
      </Wide>
    </Layout>
  );
}

export default Magazine
